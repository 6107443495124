$blue: #0837e6;
$green: #49A942;
$red: #dd575f;
$yellow: #ede09c;
$purple: #bf95d3;
$pastel-blue: #dcecff;
$pastel-green: #e0f6ef;
$pastel-red: #ffdad6;
$pastel-yellow: #fff4d7;
$pastel-purple: #f3dff6;
$green-dark: #005747;
$red-dark: #ba4035;
$grey-400: #51596c;
$grey-350: #555555;
$grey-300: #8e939f;
$grey-200: #dddee0;
$grey-150: #e7e7e7;
$grey-100: #f7f8fa;
$white: #fff;
$black: #000;
$neutral-500: #6a6e76;
$neutral-800: #292b30;
$dark: #1f2430;
$dark-red: #cc0000;
$pastel-blue-50: #f1f5ff;
$emerald-forest: #2CA048;


.bg-grey-150 {
  background-color: $grey-150;
}

.text-grey-350 {
  color: $grey-350;
}
