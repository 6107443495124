@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");

@font-face {
  font-family: "DIN";
  src: url("../fonts/din.eot");
  src: url("../fonts/din.eot?#iefix") format("embedded-opentype"),
    url("../fonts/din.woff") format("woff"),
    url("../fonts/din.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "DIN";
  src: url("../fonts/din-bold.eot");
  src: url("../fonts/din-bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/din-bold.woff") format("woff"),
    url("../fonts/din-bold.ttf") format("truetype");
  font-weight: bold;
}

