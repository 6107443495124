@import "constants";

header#top {
  background: #005747;
  box-shadow: 0 1px 1px rgba(black, .1);
  z-index: 1;
  display:flex;
  padding: 17px 50px;
  align-items: flex-end;

  #flash {
    position: absolute;
    top: 0;
    left: 250px;
    height: 80px;
    align-items: center;
    display: flex;
    background: transparent;
    padding: 0;
    z-index: 2;

    .alert {
      padding: 3px 10px;
      margin: 0;
    }
  }


  .information-navbar {
    display: flex;
    max-width:400px;
    align-items: flex-end;
    margin-bottom: 3px;

    > div {
      margin-left:30px;

      p{
        font-size:13px;
        color:#fff;
        height:18px;
        padding:0px;
        margin:0px;
      }
      p:last-child {
        font-size:14px;
        font-weight:bold;
      }
    }
  }
}

.brand {
  align-self: start;
}