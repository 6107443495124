@import "constants";

.lists, .list-content {
  position: absolute;
  top: 0;
  bottom: 0;
}

.list-content{
  overflow-y:scroll;
  overflow-x:hidden;
}

.list-content .table-bordered {
  border: none;
}

.list-content {
  position: absolute;
  top: 100px;
  left: 200px;
  right: 0;

  .content-header {
    margin-bottom: 0;
    position: relative;
    z-index: 100;
  }

  #customers-wrapper {
    min-height:calc(100vh - (165px + 250px));/*top nav + search modal + admin panel*/
    left: 0;
    right: 0;
    bottom: 0;
    overflow: visible;
    overflow-x: hidden;

    th {
      padding:10px 18px 10px 10px;
    }
  }
}

.lists {
  padding: 0;
  width: 200px;
  background-color: $alt-background;
  top: 80px;
  z-index: 1030;
  display: block;
  position: fixed;
  bottom:0px;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background:rgba(250,250,250,0.5);
  }
  ::-webkit-scrollbar-thumb {
    background: $medium-green;
  }

  .heading {
    position: absolute;
    top: 0;
    padding-top: 25px;
    height: auto;
    left: 15px;
    right: 15px;
    background-color: rgba($alt-background, .7);
    z-index: 10;
  }

  ul {
    font-family: $open-sans;
    height: 100%;
    list-style: none;
    padding: 78px 0 64px 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: visible;
  }

  footer {
    background-color: rgba($alt-background, .7);
    padding: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .lists-logo {
    width: 100px;
  }
}

.list-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  padding: 6px 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  &.active {
    background:#48993B;
    margin: 0 42px 0 0;
    &:hover {
      .list-controls {
          display: none;
      }
    }
    a {
      color: white;
      font-weight: 600;
    }
  }
  & > a {
    position: relative;
    max-width: 100%;
    color: #48993B;
    font-weight: 400;
    padding: 6px 4px 6px 14px;
  }

  & .active {
    color: white;
    font-weight: 600;
  }

  .list-controls {
    position: absolute;
    top: 6px;
    right: 6px;
    display: none;
  }

  &:hover .list-controls {
    display: block;
  }
}

.list-item + .list-item {
  margin-top: 4px;
}
.list-item + .list-add {
  margin-top: 6px;
  padding: 2px 4px 2px 14px;
}

.sum {
  color: #347E2A;
  margin-right: 25px;
}

#spinner {
  font-size: 2em;
}

.spinner_wrapper {
  margin: 0 auto;

  .spin {
    margin-top: 50px;
    display: block;
    width: 100%;
    text-align: center;
  }
  #no_more_customers {
    text-align: center;
    font-weight: bold;
    display: none;
  }
}
