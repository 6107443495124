@import "constants";

@-webkit-keyframes autoComplete {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ui-autocomplete {
  position: absolute;
  font-size: inherit;
  background: $background-color;
  font-family: inherit;
  box-shadow: 0 1px 20px rgba(black, 0.2);
  -webkit-animation: autoComplete 0.3s ease;
  border-radius: 4px;
  padding: 4px 0;
  list-style-type: none;
  border: 1px solid #aaa;
  color: #222;

  .ui-state-active {
    background: $alt-background;
    outline: 1px solid darken($alt-background, 20%);
  }

  .ui-menu-item-wrapper {
    position: relative;
    padding: 3px 1em 3px 30px;
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: inherit;

    .fas {
      font-size: 14px;
      position: absolute;
      left: 7px;
      top: 50%;
      margin: -9px 0 0 0;
      opacity: 0.3;
    }

    small {
      opacity: 0.7;
    }
  }
}
