@import "constants";
.contacts {
  .contact {
    padding: 5px;
    a {
      color: #333;
      &:hover, &:active, &:focus {
        text-decoration: none;
      }
      small {
        margin: 0 0 3px;
      }
    }
    &:hover {
      background-color: $hover-background;
    }
  }
  .contact:nth-of-type(even) {
    background-color: $alt-background;

    &:hover {
      background-color: $hover-background;
    }
  }
  .contact + .contact, .contact-add + .contact {
    margin-top: 8px;
  }
}
.titles {
  span {
    display: block;
    input {
      margin-right: 5px;
    }
  }
}
