@import "constants";

html, body, #application {
  min-width: 100%;
  min-height: 100%;
}

#application,
#top,
#sidebar {
  position: absolute;
  overflow: auto;
}

#application {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

header#top {
  position: absolute;
  width: 100%;
  height: $header-height;
  margin: 0;
  z-index: 1000;
}

#sidebar {
  position: absolute;
  width: $sidebar-width;
  top: $header-height;
  bottom: 0;
  left: 0;
  z-index: 1030;
  display: block;
}

#main-content {
  margin: 105px 0 0 0;
}

.inner-content {
  height: 100%;
  padding: 0px 15px 0 15px;

}
.list-content {
  .content-header {
    height:70px;
    .heading {
      padding: 25px 10px;
    }
  }
}
.content-header {
  width: 100%;
  position: relative;
  border-bottom: 1px solid $top-border-color;
  margin-bottom: 0;
  overflow:hidden;

  .heading {
    float: left;
    padding: 25px 15px;
    display: inline-block;
    height: auto;
  }

  .modal-sum {
    padding: 25px 0;
    float: left;
    height: auto;
    font-weight: bold;
    span {
    }
  }
  .rightAction{
    float:right;
    justify-content: flex-end;
  }
  .actions {
    margin-top: 15px;
    padding: 0 0;
    margin-left: 8px;
    display: flex;

    .form-control {
      width:200px;
      color:#000;
      font-weight:bold;
    }

    .form-group {
      display:flex;
      align-items: center;

    }
  }

}

.edit-user-header.content-header{
  position:absolute;
  top:0px;
  left:0px;
}

.edit-user-wrapper {
  margin-top:70px;
}

.modal-sum {
  display: inline-block;
  float: right;
  margin-right: 30px;
  height: auto;
  font-weight: bold;
  span {
  }
}

.btn {
  &:focus, &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}


/* administration bar */


.customer-menu-wrapper {
  background:#eee;
  height:auto;
  overflow:hidden;
  .heading-sums >span{
    display:block;
  }
  ul {
    display: flex;
    list-style:none;
    padding:0px;
  }
  ul li:last-child {
    margin-left:auto;
  }
  ul li:nth-last-child(3) span{
    margin-top:11px;
  }

  button{
    border:none;
    background:none;
    text-align: center;

    img {
      max-height:60px;
    }
    span {
      display: block;
      color:#000;
      font-weight:bold;

      top: 0;
      margin: 10px 0px 0px 0px;
      line-height: 10px;
      padding: 0;
    }
  }
  .inner-customer-module:first-child {
    border:none;
    padding-left:10px;
  }
  .inner-customer-module:nth-child(2){
    padding:10px 10px !important;
  }
  .inner-customer-module {

    flex:1;
    height:calc(100% - 20px);
    min-height:290px;
    padding:10px 10px;
    margin:10px 0px;
    border-left:1px solid #bbb;

    &.export-module a {
      padding:30px 0px 40px 0px;
    }
  }

  .sum {
    color:#000;
    font-weight:bold;

    span {
      color: $medium-green;
    }
  }
  #sum_wrapper {
    margin:25px 5px 0px 14px;
    > span {
      display:block;
    }
    .form-control{
      width:200px
    }
  }
  #sum_customer {
    margin-top:10px;
  }
  #sum_country {
    margin-bottom:20px;
    .select  {
      display: flex !important;
      align-items: center;

    }
    .select label{
      margin:0;
      padding-right:10px
    }
  }
  #sum_list {
    font-size:14px;
    /*capitalize only first letter*/
    a {
      display:inline-block;
    }
    a:first-letter {text-transform: uppercase}

  }
  #sum_subjects {
    font-size:14px;
    margin:10px 0px 0px 0px;
  }
}


.settings-title {
  font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin: 15px 0 0 0;
    padding:0px 0px 15px 0px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-icon {
  width: 50px;
  height: 50px;
}

.navbar-icons {
  display: flex;
  justify-content: space-around;
  margin-left: 20%;
}

.navbar-icon-container {
  position: relative;
  margin-right: 80px;
}

.navbar-icon-container a{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navbar-icon-container.active::after {
  content: "";
  position: absolute;
  left: -35px;
  right: -35px;
  bottom: -17px;
  height: 12px;
  background-color: $medium-green;
  margin: 0 26px;
}

.navbar-title {
  color: white;
  text-align: center;
  font-weight: bold;
} 

@media (max-width: 1348px) {
  .navbar-icon-container {
    margin-right: 40px;
  }
}

@media (max-width: 1348px) {
  .navbar-icons {
    margin-left: 0;
    justify-content: center;
  }
}


#flash {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
