@import 'bootstrap/dist/css/bootstrap.css';

.cursor-pointer {
  cursor: pointer;
}

input[type='file'].custom-attachments-input {
  display: none;
}
.modal-content {
  border-radius: 0;
}

input, .form-control {
  font-size: 1.4rem;
}

button, .btn {
  font-size: 1.6rem;
  padding: 12px;
}

label {
  font-weight: 700;
  margin-bottom: 5px;
}
small, .small {
  font-size: 12px;
}
.form-inline {
  .form-group {
    display: inline-block;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
  label {
    justify-content: flex-start;
    margin-bottom: 5px;
  }
}

a:not([href]):not([class]) {
  color: $white;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: $green;
  text-decoration: none;
}

.nav-tabs {
  border: none;
  font-family: $open-sans;

  li {
    border-right: 2px solid $white;
  }

  .nav-link {
    background-color: $green;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &.active,
    &:hover {
      background-color: $white;
      color: $green;
      border: none;
    }

    &.disabled {
      background-color: grey;
      color: $white;
    }
  }

  a {
    display: flex;
    justify-content: center;
    padding: 20px;
    min-width: 180px;
    font-weight: 700;
    color: $white;
    text-decoration: none;
    cursor: pointer;
  }
}

.modal-backdrop {
  z-index: 2299;
}
.modal-dialog {
  min-width: 600px;
  max-width: 950px;
}
.modal-body {
  padding: 2rem 0;
}

.form-control-feedback {
    display: block;
    height: 30px;
    line-height: 34px;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 30px;
    z-index: 2;
}

.text-green {
  color: $link-hover-color;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled {
  pointer-events: none;
  color: $grey-300;
  opacity: 0.5;
}

.input-error {
  border-color: $red;

  &:focus {
    border-color: $red;
  }
}
