//Fonts
$open-sans: 'Open Sans', sans-serif;

// Layout
$header-height:       105px;
$sidebar-width:       50px;

// Color palette
$dark-green:          #005747;
$medium-green:        #48993B;
$light-grey:          #F5F5F5;


$dark-ground:         #005747;
$background-color:    #f5f5f5;
$button-border-color: #2F7226;
$link-hover-color:    #347E2A;
$grey-text:           #666;
$link-color:          #3F9533;
$alt-link-color:      #202020;
$navbar-background:   #343D48;
$border-color:        #D1D1D1;
$top-border-color:    #E7E7E7;
$alt-background:      #DAE7A2;
$hover-background:    #cadd79;
$light-grey-text:     #9B9B9B;
$pale-lime:           #DDE6AA;
