.tablesorter {
  .tablesorter-icon {
    font-size: 11px;
    position: absolute;
    top: 4px;
    right: 2px;
    width: 14px;
    height: 14px;
    display: inline-block;

    &.bootstrap-icon-unsorted {
      top: 2px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAOCAMAAADOvxanAAAAVFBMVEUAAABCQkJZWVkZGRnJyckgICAZGRkZGRn8/PweHh4dHR0aGhoaGhpUVFQbGxvQ0NDc3NxMTExSUlIbGxvr6+s4ODhKSkogICAtLS00NDQzMzMnJydSEPrQAAAAGHRSTlMA1ssZRLgdAQbDyisqsZo8QdXUq0r9xPepSRwiAAAAX0lEQVQI13XHSQKAIAwEwQAKxn13Ev7/T2Pu9qmarJKPXIicI4PH4hxaKNrhm2S8bJK5h4YzKHrzJNtK6yYT/TdXzpS5zuYg4MSQYF6i4IHExdw1UVRi05HPrrvT53a+qyMFC9t04gcAAAAASUVORK5CYII=) no-repeat;
    }
  }
}
table
{
  &.dataTable {
    &.no-footer {
      border-bottom: 1px solid #c7c7c7 !important;
    }
    thead {
      th {
        border-bottom: none !important;
      }
      .sorting {
        background-image: url("../images/unsorted.png") !important;
        background-position: 95% 50% !important;
      }
      .sorting_asc {
        background-image: url("../images/up.png") !important;
        background-size: 10px 7px;
        background-position: 95% 50% !important;
      }
      .sorting_desc {
        background-image: url("../images/down.png") !important;
        background-size: 10px 7px;
        background-position: 95% 50% !important;
      }
    }
  }
}
