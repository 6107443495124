@import "constants";

.form-control:focus {
  border-color: rgba(#49A942, .7);
  -webkit-box-shadow: 0 0 8px rgba(#49A942, .4);
  -box-shadow: 0 0 8px rgba(#49A942, .4);
}

.input-group {
  #search_icon {
    opacity: .5;
  }

  & > .form-control {
    -webkit-box-shadow: none;
    -box-shadow: none;
    -webkit-transition: none;
    transition: none;
    border-radius: 15px;
  }
  & > .form-control, & > .search {
    color: $link-color;
    border-radius: 4px !important;
  }
  & > .input-group-addon {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.input-group-addon {
  color: #ffffff;
  background-color: $link-color;
}

.nav-bottom {
  position: fixed !important;
  bottom: 0;
}

.navbar-default {
  margin: 0;
}

a,
.navbar-default .navbar-nav>li>a {
  color: $link-color;
  &:hover, &:focus {
    color: $link-hover-color;
  }
}

.btn {
  border-radius: 2px;
}

.btn + .btn {
  margin-right: 10px;
}

.btn-primary {
  background-color: $link-color;
  border-color: $button-border-color;
  &:hover, &:focus {
    background-color: $link-hover-color !important;
  }
}

$top-margin: 60px;

.content {
  margin-left: 50px;
  max-width: 100% !important;
}

.radio, .radio-inline {
  input[type='radio'] {
    margin-left: 0;
  }
}

.center {
  max-width: 400px;
  padding: 15px;
  margin: 0 auto;
}

.separator {
  border-bottom: 1px solid $border-color;
}

.navbar-top > .container-fluid {
  padding-left: 0;
}

.navbar-fixed-top, .right-aligned {
  position: fixed;
  right: 0;
  left: 265px;
}

.right-aligned {
  overflow: scroll;
  height: 100%;
  padding-right: 9px;
}

.navbar-fixed-top {
  height: $top-margin;
}

.navbar-full-top {

}

.top-margin  {
  margin-top: $top-margin;
}
.ui-autocomplete {
  z-index: 1031;
}

.table {
  & > thead, & > tbody {
    & > tr {
      & > td, & > th {
        border-top: none;
        border-bottom: none;
      }
    }
  }
}

.not-active {
  pointer-events: none;
  cursor: default;
  color: $grey-text;
}

.highlight {
  background-color: #999 !important;
}

// Simple_form inline
.controls-inline {
  float: left;
  display: inline-block;
}

.checkbox-inline {
  margin-bottom: 15px;
}

.list, .customer {
  width: 100%;
  float: left;
  padding-left: 15px;
  padding-right: 19px;
  margin-top: 15px;
  padding-bottom: 25px;
}

.modal {
  label {
    input + span {
      display: inline;
    }
    span {
      font-weight: normal;
      margin-left: 10px;
    }
  }
}


