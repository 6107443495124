@import 'constants';

.horizontal_separator {
  position: relative;
  height: 100%;
  border-right: 1px solid $border-color;
}

.horizontal_separator:last-of-type {
  border-left: 1px solid $border-color;
  border-right: none;
  min-height: 296px;
}

.horizontal_separator:nth-last-of-type(2) {
  border: none;
}

.customer_address > textarea {
  height: 108px;

}

.customer_zip_code > .zip_code {
  width: 70px;
}

.table {
  thead > tr, tbody > tr {
    & > th, & > td {
      &:first-child {
        padding-left: 15px;
      }
    }
  }
}

div.spacer, div.wide-spacer {
  margin-top: 15px;
  border-bottom: 1px solid $border-color;
  margin-bottom: 15px;
}

div.wide-spacer {
  border-bottom: 1px solid $top-border-color;
  margin-left: -15px;
  margin-right: -15px;
}


tr[class*='customer-'] {
  cursor: pointer;
}

#notes {
  table {
    display: table;
    width: 100%;

    thead, tbody {
      float: left;
      width: 100%;
    }

    tbody {
      overflow: auto;
      height: 150px;
    }

    tr {
      width: 100%;
      display: table;
      text-align: left;
    }

    th.created_at, td.created_at {
      padding-left: 20px;
      text-align: left;
    }
  }
}


