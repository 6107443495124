@import "constants";

body.login {
  background: $alt-background;


  h2, .btn {
    font-weight: bold;
  }
  h2, label {
    color: $dark-green;
  }

  .container {
    width: 100%;
    float: left;
    margin: 100px 0;
  }

  form {
    margin-bottom: 15px;
  }

  .form-control {
    border-color: $dark-green;
  }

  .checkbox-inline {
    margin-bottom: 10px;
  }
}
