@import "constants";


html {
  font-size: 10px;
}

body {
  font-family: "DIN", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
}

.heading {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 50px;
}
