@import "constants";

.no-padding {
  padding: 0;
}

.transparent-overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  z-index: 2100;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
}

.overlay-content {
  position: relative;
  z-index: 2100;
  background: white;
  top: 7vh;
  min-height: 690px;
  max-width: 60%;
  margin: 0 auto;
  display: none;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  &.small {
    width: 600px !important;
  }
  .form-group {
    input {
      background: #D8D8D8;
      border: 1px solid #DDDDDD;
      height: auto;
    }
  }
  .form-group.customer_lists input{
   top:-1px
  }

}

.overlay-contact {
  position: fixed;
  z-index: 11;
  width: 70%;
  height: 100px;
  background: white;
  left: 15%;
  right: 15%;
  top: 10vh;
  display: none;
}
.customer-ajax-spinner {
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  font-size: 40px;
  color: white;
  z-index: 99999;
}
.spinner-table {
  position: fixed;
  display: block;
  left: 55%;
  top: 400px;
  font-size: 30px;
  z-index: 1;
}
.customer-table {
  z-index: 99;
}

.modal-dialog {
  margin: 80px auto;
}

.color-class {
  background: white !important;
  color: #3F9533 !important;
  border-color: white !important;
}

.form-notes {
  display: none;
}

.scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
}

.form-contact {
  display: none;
}
.form-update {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 1.5rem;
  &.operator-form {
    position: relative;
  }
}
.export_gift, .export_newsletter, .export_post {
  margin-bottom: 0;
  input[type="checkbox"] {
    float: left;
    margin-right: 15px;
  }
  label {
    font-weight: normal;
  }
}
.export-countries {
  input[type="checkbox"] {
    float: left;
    margin-right: 15px;
  }
  input, span {
    margin-bottom: 5px;
  }

}
.newsletter-post-wrapper {
  display: flex;
  justify-content: space-between;
  > div:last-child {
    margin-right:40px;
  }
  input {
    position:relative;
    bottom:-1px;
  }
}
.contact_post,.contact_gift, .contact_newsletter,.contact_newsletter_country {
  .gift-form-wrapper {
    margin: 0;
    label {
      padding-left: 15px;
      font-weight: normal;
    }
    span {
      display:block;
    }
  }
}
.contact_titles {
  label {
    margin-bottom: 0;
  }
  input {
    position:relative;
    top:-2px;
  }
}
.no-pad {
  padding: 0;
}
.contact-wrapper input {
  background:#D8D8D8;
  border:1px solid #DDDDDD;
}

.customer {
  padding: 0;
  margin: 0;
}
.close-customer {
  padding: 25px 32px;
  opacity: 1;
  float: right;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    transition: 0.3s;
  }
}
.close-btn {
  padding: 22px 32px;
  opacity: 1;
  float: right;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}
.form-content {
  .form-list {
    overflow: auto;
    max-height: 300px;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background:rgba(250,250,250,0.5);
  }
  ::-webkit-scrollbar-thumb {
    background: $medium-green;
  }
}

.customer-wrapper.form-content{
  margin:20px 0px 50px 0px;
  min-height:550px;
}

.delete-btn {
  font-size: 16px;
  font-weight: 400;
  color: #D0021B;
  padding: 12px 33px;
  &:hover {
    color: #D0021B;
    font-weight: 700;
    transition: 0.3s;
    text-decoration: none;
  }
}

.update-btn {
  font-size: 16px;
  font-weight: 700;
  color: white;
  padding: 12px 12px;
	border-radius: 5px;
	background-color: #48993B;
  margin-top: 20px;
  &.save-btn-stay, &.new-btn {
    background: transparent;
    color: #48993B;
    &:hover {
      opacity: 0.8;
      transition: 0.3s;
      color: #48993B;
      text-decoration: none;
    }
    &:focus {
      color: #48993B;
    }
  }
  &:hover {
    opacity: 0.8;
    transition: 0.3s;
    color: white;
    text-decoration: none;
  }
  &:focus {
    outline: none;
    color: white;
  }
}


/* custom trigger buttons on overlay*/
.category-btn-wrapper {
  margin:30px 0px 0px 0px;
}

.category-btn{
  font-size: 14px;
}

.category-btn-ul{
  display:flex;
  justify-content: flex-start;
  margin:0 0 40px 0;
  padding:0;

  li {
    list-style-type:none;
    padding:0px;
    margin:0px;

    button {
      background:none;
      border:none;
      padding:5px 6px;
      text-align: center;
      display: block;
      img {
        height:60px;
      }
      span {
        margin-top:10px;
      }
    }
  }
  li:first-child button{
    padding-left:0px;
  }
  li:nth-child(3) button{
    padding:5px 2px;
  }
}


.category-bold-font,.category-btn-ul button>span {
  font-weight: bold;
  font-size: 14px;
  width:100%;
  display: block;
}


.modals-overlay {
  display: none;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  position: fixed;
}

.new-contact {
  display: none;
}

.new-customer {
  cursor: pointer;
}
#notes-table {
  .activity-wrapper {
    background: #F5F5F5;
    padding: 18px 0 16px 0;
    border-radius: 5px;
  }
  .root-activity {
    margin-bottom: 15px;
  }
}
.note-content {
  clear: both;
  .note-text {
    margin: 10px 0;
  }
  &:first-child {
    border-top: none;
  }
  .spacer20 {
    padding-bottom: 20px;
  }
}

.profile-picture {
  img {
    border-radius: 50%;
  }
  text-align: center;
}

.note-content {
  .company-name {
    display: flex;
    margin-bottom: 10px;
    img {
      padding-right: 5px;
      margin-bottom: 5px;
    }
  }
  .profile-picture{
    img {
      float: right;
      margin-right: 8px;
    }
  }
  .activity-wrapper {
    min-height: 135px;
  }
}

.note-created {
  color: gray;
  margin-bottom: 5px;
  text-transform: lowercase;
}

.note-edit {
  color: gray;
  cursor: pointer;
}
.spacer20 {
  margin-top: 20px;
  padding-bottom: 0;
}

.nested-notes {
  margin-bottom: 15px;
  .note-created {
    padding-left: 0;
  }
  .note-content {
    border: none;
    .company-name {
      img {
        display: none;
      }
    }
    &:last-child {
      border-bottom: 1px solid #DAE7A2 !important;
      div {
        font-size: 20px;
      }
    }
    .spacer20 {
      margin-top: 0;
    }
    .activity-wrapper {
      min-height: 110px;
    }
  }
}
// TODO: this class is not used anywhere
.new-activity {
  width: 70px;
  height: 70px;
  background: #005747;
  border-radius: 50%;
  position: fixed;
  right: 5%;
  bottom: 5%;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    transition: 0.4s;
  }
  .fas {
    font-size: 30px;
    color: white;
    margin: 27% 30%;
  }
}
.activity-modal {
  background: white;
  z-index: 99;
  border-radius: 5px;
  margin-bottom:20px;
  .modal-header {
    border-bottom: none;
    padding: 25px 30px 0 30px;
  }

  .modal-footer {
    border: none;
    padding: 0 30px 15px 30px;
    .btn {
      font-family: $open-sans;
      font-weight: 700;
      width: 160px;
      border-radius: 5px;
      margin-right: 0;
    }
    .btn-primary {
      background-color: #48993B;
      border-color: #48993B;
    }
  }
  h4 {
    color: #3E9531;
    font-family: $open-sans;
    font-weight: 700;
  }
  .form-group {
    textarea {
      background-color: #EBEBEB;
      border: 1px solid #EBEBEB;
    }
  }
  .activity-checkbox {
    margin-left: 20px;
  }
}

#default_modal {
  .modal-content {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin: 0 auto;
    border-color: transparent;
    .modal-header {
      padding: 16px 0 0;
      border-bottom: none;

      h4 {
        color: #3E9531;
        padding-left: 30px;
        font-family: $open-sans;
        font-weight: 700;
      }
    }
    .modal-body {
      padding: 15px 30px;

      .form-control {
        background-color: #EBEBEB;
        border: 1px solid #EBEBEB;
      }
    }
    .modal-footer {
      border-top: none;
      padding: 15px 30px;
      .btn {
        font-family: $open-sans;
        font-weight: 700;
	      width: 160px;
        border-radius: 5px;
        margin-right: 0;
      }
      .btn-danger {
        background: #b35856;
        border-color: #b35856;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      .btn-primary {
        background-color: #48993B;
        border-color: #48993B;
      }
    }
  }
}
.country-explained {
  font-size: 85%;
  margin-top: -8px;
  text-align: center;
}
.new_user {
  .country-explained {
    text-align: left;
  }
}
.edit_user {
  .form-content {
    margin: 0;
  }
}
.nav {
  .new-btn {
    &:hover {
      background-color: transparent;
    }
  }
}
.cancel-btn {
  border: none;
  background-color: transparent;
  color: #48993B;
  &:hover {
    opacity: 0.7;
    color: #48993B;
    background-color: transparent;
  }
}
.modal-dialog {
  margin: 10vh auto;
}
.spacer30 {
  height: 30px;
}

#export_modal,
#contact_modal,
#default_modal {
  z-index:2300;
}

/* pretty alert */
.niceAlert {
  position:fixed;
  top:30vh;
  left:calc(50% - (300px/2));
  z-index:3000;
  width:350px;
  box-shadow:0px 0px 200px #333;
  -webkit-box-shadow:0px 0px 200px #333;
  background:#fff;
  padding:30px;
  >p:first-child {
    font-size:20px;
    font-weight:bold;
    border-bottom:1px solid #aaa;
    padding:0px 0px;
  }
  >p:last-child {
    font-size:15px;
    padding:10px;
    color:#aaa;

  }
  >button{
    font-size: 16px;
    font-weight: 700;
    color: white;
    padding: 12px 12px;
    border-radius: 5px;
    background-color: $medium-green;
    float:right;
    border:none;
    min-width:80px;
  }
}
