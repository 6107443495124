$btn-bg: #E7E7E7;
$btn-hover-bg: #D7D7D7;
$text-color: #555555;

@mixin transition($property, $duration, $ease) {
  transition: $property $duration $ease;
}

.custom-attachments-upload-btn {
  padding: 10px 15px;
  cursor: pointer;
  background-color: $btn-bg;
  color: $text-color;

  @include transition(background-color, 0.2s, ease);

  &:hover {
    background-color: $btn-hover-bg;
  }
}

.attachments-list {
  ul {
    list-style-type: none;
    padding-inline-start: 0px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  li, .attachment-link {
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 6px 12px;
  }
}
