@import "constants";

#sidebar {
  background: $navbar-background;
  z-index: 2000;

  ul {
    li {
      margin-top: 3px;
      span {
        font-size: 1.4em;
      }
    }
  }

  a {
    margin: 0;
    color: #ffffff !important;

    &:hover, &:focus {
      background: $navbar-background !important;
      color: #999 !important;
    }
  }
}
