@import "constants";

.activities-log {
  display: flex;
  font-family: $open-sans;
  background: #e7e7e7;
  top: 0 !important;
  padding: 20px 0 30px 0;
  min-height: 100%;
  word-wrap: break-word;

  #activities-table {
    .activity-wrapper {
      background: white;
      border-radius: 5px;
    }
    .activity-wrapper.pale-lime {
      background: $pale-lime;
    }
    .note-content {
      border: none !important;
      margin: 20px 0;
    }

    .nested-notes {
      .activity-wrapper {
        border-radius: 5px;
      }
      .note-content {
        min-height: 0;
        margin: 30px 0;
      }
    }
  }
}
.ajax-spinner {
  font-size: 32px;
  margin-bottom: 20px;
  display: none;
}
.load-more {
  font-size: 20px;
  margin-bottom: 20px;
}
.load-more-container {
  text-align: center;
  margin-bottom: 50px;
}
.profile-picture-md {
  img, svg {
    height: 52px;
    width: 52px;
  }
}
.profile-picture-sm {
  img, svg {
    height: 32px;
    width: 32px;
  }
}

.pdf-icon {
  height: auto;
}

.proposal-activity-content {
  padding-left: 10px;
}

.proposal-activity-content p {
  font-size: 16px;
  margin: 0;
  line-height: 1.75;
}

.image-presenter-1 {
  a:nth-child(1) {
    width: 100%;
  }
}

.image-presenter-2 {
  a:nth-child(1) {
    width: 100%;
    margin-bottom: 5px;
  }

  a:nth-child(2) {
    width: 100%;
  }

}
.image-presenter-3 {
  a:nth-child(1) {
    width: 100%;
    margin-bottom: 5px;
  }
  a:nth-child(2) {
    width: 50%;
    padding-right: 2.5px;
  }

  a:nth-child(3) {
    width: 50%;
    padding-left: 2.5px;
  }
}

.right-side-icon {
  width: 60px;
  height: 60px;
  background-color: $emerald-forest;
  border-radius: 50%;
}

.announcement {
  background-color: $dark-red;
}