@import "constants";

.table-striped {
  width: 100%;
  thead {
    background: $alt-background;
  }

  tbody > tr {
    &:nth-of-type(odd) {
      background: transparent;
    }

    &:nth-of-type(even) {
      background: $alt-background;
    }
  }
}

.table-bordered {
  & > thead > tr > th {
    border-color: darken($alt-background, 10%);
    .header {
      display: inline-block;
      margin-right: 20px;
    }
  }

  & > tbody > tr {
    &:nth-of-type(even) td {
      border-color: darken($alt-background, 10%);
    }

  }
}

.table-hover {

  &.table-striped tbody {
    tr:nth-of-type(odd):hover {
      background: darken($background-color, 5%);
      cursor: pointer;
    }

    tr:nth-of-type(even):hover {
      background: darken($alt-background, 10%);
      cursor: pointer;
    }
  }
}
.disabled-link {
  pointer-events: none;
  color: black;
}
.dataTables_wrapper {
  margin: 10px;
  font-family: $open-sans;
  .top, .bottom {
    margin: 25px 0;
    height: 42px;
  }
  .ellipsis {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em !important;
    border-left: none !important;
    border-top: 1px solid #DDDDDD !important;
    border-right: 1px solid #DDDDDD !important;
    border-bottom: 1px solid #DDDDDD !important;
  }
  .paginate_button {
    color: white !important;
    margin-left: 0px !important;
    border: 1px solid #DDDDDD !important;
    border-left: none !important;
    &.previous {
      border-right: none !important;
      &:hover {
        color: white !important;
      }
    }
    &:first-child {
      border-left: 1px solid #dddddd !important;
    }
    &.current {
      background: #48993B !important;
      border-color: #48993B !important;
    }
    &:hover {
      background: #48993B !important;
      border-color: #48993B !important;
    }
  }
}

.dataTables_length {
  margin: 0 10px;
  select {
    padding: 9px 20px 9px 5px;
    border-color: #DDDDDD;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: image-url("../images/up-down-arrows@2x.png") 80% / 11% no-repeat white;
    background-position: 88% 50%;
  }
}

.customer-table {
  .odd {
    background: white !important;
    &:hover {
      background: #e8e8e8 !important;
    }
  }
  thead {
    th {
      &:nth-last-child(-n+2) {
        &.sorting {
        background-image:none !important;
        pointer-events: none;
        }
      }
    }
  }
}
